import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
export const _frontmatter = {
  "title": "IN-Route P52 - System",
  "path": "/Products/IN-Route/System/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "System Menu",
  "image": "../P_SearchThumb_MobileRouter.png",
  "social": "/images/Search/P_SearchThumb_MobileRouter.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Route_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "in-route-p52",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-route-p52",
        "aria-label": "in route p52 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-Route P52`}</h1>
    <h2 {...{
      "id": "system",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system",
        "aria-label": "system permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System`}</h2>
    <ul>
      <li parentName="ul">
        <Link to="/Products/IN-Route/Overview/File_Explorer/" mdxType="Link">File Explorer</Link>
      </li>
      <li parentName="ul">
        <Link to="/Products/IN-Route/Overview/Mode/" mdxType="Link">Mode</Link>
      </li>
      <li parentName="ul">
        <Link to="/Products/IN-Route/Overview/Status/" mdxType="Link">Status</Link>
      </li>
      <li parentName="ul">
        <Link to="/Products/IN-Route/Overview/WAN_Status/" mdxType="Link">WAN Status</Link>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      